/* stylelint-disable selector-no-qualifying-type */
body {
	padding-top: 92px;
	line-height: 1.5em;

	&.admin-bar {
		.c-header {
			top: 32px;

			@include bp-down(medium) {
				top: 46px;
			}
		}
	}
}
/* stylelint-enable selector-no-qualifying-type */
