.b-flexible-content {
	$root: &;

	padding: 1%;
	// padding: 40px 0;

	@include bp-down(medium) {
		padding: 20px 0;
	}

	&--reverse-cols {
		.row {
			display: flex;
			flex-direction: row-reverse;
		}
		#{$root}__content {
			padding-left: 0;
			padding-right: 40px;

			&--rm-padding {
				padding-right: 0;
			}

			@include bp-down(medium) {
				padding-right: 0;
			}
		}
	}

	&--col-image-mobile-bottom {
		@include bp-down(medium) {
			.row {
				flex-direction: column-reverse;
			}
		}
	}

	&__col {
		&--centered {
			text-align: center;
		}

		&--remove-mobile-padding {
			@include bp-down(medium) {
				padding: 0;
			}
		}
	}

	&__img {
		max-width: 100%;
		height: auto;
	}

	&__content {
		padding-left: 40px;

		&--rm-padding {
			padding-left: 0;
		}

		@include bp-down(medium) {
			padding-left: 0;
			margin: 20px 0;
		}
	}

	a {
		// display: inline-block;
		// margin: 15px 0 0 0;
		display: inline;
		margin: 0;
	}
}
