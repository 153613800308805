.c-box-single-doctor {
	background: $color-white;
	border: 1px solid $color-gray-border-v2;
	border-radius: 4px;
	height: 100%;

	&__img {
		width: 123px;
		height: 123px;
		border-radius: 50%;
		object-fit: cover;

		&-wrapper {
			text-align: center;
			padding: 20px 20px 24px;
		}
	}

	&__header {
		text-align: center;
		padding: 0 10px;
	}

	&__name {
		margin-bottom: 2px;
		font-size: 18px;
		font-weight: 600;
		color: $color-secondary;
	}

	&__icon-ok {
		display: inline-block;
		vertical-align: middle;
		padding-right: 8px;
	}

	&__specialization {
		font-size: 16px;
		line-height: 1.2;
		letter-spacing: 0.25px;
		color: $color-secondary;
	}

	&__rating {
		margin: 24px 0;
		padding: 0 10px;
	}

	&__review-wrapper {
		padding: 24px;
		border-top: 1px solid $color-fill-grey;
	}

	&__link-wrapp {
		text-align: center;
		margin: 30px 0;
	}
}
