.c-footer-nav {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__header {
		text-align: left;
		margin-bottom: 15px;
		font-size: 1.125rem;

		@include bp-down(large) {
			text-align: center;
		}
	}

	&__item {
		&--empty {
			margin-bottom: 20px;
		}

		&--margin-small {
			margin-bottom: 2px;
		}
	}

	&__link {
		width: 100%;
		font-size: em(14);
		line-height: 1.71;
		transition: $global-transition;

		&:not([href='#']) {
			&:hover {
				color: $color-fill-accent !important;
			}
		}

		&[href='#'] {
			cursor: unset;
		}
	}
}
