/* stylelint-disable selector-no-qualifying-type */

.b-slider {
	&__text {
		max-width: 40rem;
		font-weight: 700;
		color: $color-white;
		margin: -25px 0 0 0;
		font-size: 16px;
		line-height: 24px;

		@include bp-down(medium) {
			max-width: none;
		}

		span {
			display: initial;
			background-color: #11c0f2;
			box-shadow: 10px 0 0 0 #11c0f2, -10px 0 0 0 #11c0f2;
			box-decoration-break: clone;
			font-size: em(30);
			font-weight: 700;
			line-height: em(26);

			@include bp-down(medium) {
				font-size: em(20);
				line-height: em(26);
				margin-top: -10px;
			}

			&:not(:last-child) {
				margin: 0 0 4px 0;
			}

			&.light-mark {
				background-color: #fff;
				box-shadow: 10px 0 0 0 #fff, -10px 0 0 0 #fff;
				box-decoration-break: clone;
				color: $color-blue-dark;
				font-size: em(22);
				font-weight: 600;
				line-height: em(26);

				@include bp-down(medium) {
					font-size: em(18);
					line-height: em(24);
					margin-top: -10px;
				}
			}
		}
	}

	img {
		object-fit: cover;
		height: 220px;
		width: 100%;

		// @include bp-down(medium) {
		// 	object-position: right;
		// }
	}

	&__item {
		@include media-breakpoint-up(lg) {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-image: var(--slider-image);
			min-height: 520px;
		}
	}
}

/* stylelint-enable selector-no-qualifying-type */
