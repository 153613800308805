.l-404 {
	position: relative;
	padding: 150px 0;

	@include bp-down(medium) {
		padding: 50px 0 70px;
	}

	&__title-big {
		text-align: center;
		color: $color-primary;
		font-size: 140px;
		line-height: 1;
		margin-bottom: 15px;

		@include bp-down(medium) {
			font-size: 120px;
		}
	}

	&__title {
		text-align: center;

		@include bp-down(medium) {
			font-size: 25px;
		}
	}

	&__desc {
		font-size: 30px;
		line-height: 1.3;

		@include bp-down(medium) {
			font-size: 20px;
			text-align: center;
		}
	}

	&__btn-wrapper {
		margin-top: 20px;

		@include bp-down(medium) {
			text-align: center;
		}
	}
}
