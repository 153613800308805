.c-custom-select {
	appearance: none;
	background-color: transparent;
	border: 1px solid #003b50;
	border-radius: 4px;
	padding: 7px 26px 7px 13px;
	margin: 0;
	width: 100%;
	color: #003b50;
	font-weight: 500;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
	z-index: 1;
	outline: none;
	background-image: url('../assets/images/icon-arrow.svg');
	background-position: 95% center;
	background-repeat: no-repeat;
}
