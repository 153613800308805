.c-table {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__row {
		width: 100%;
		display: flex;
		border-bottom: 1px solid $color-grey-disable;

		@include bp-down(smallxl) {
			flex-wrap: wrap;
			border-bottom: 3px solid $color-grey-disable;
		}

		&:last-child {
			border-bottom: none;
		}

		&--head {
			background-color: $color-border-review;

			@include bp-down(smallxl) {
				display: none;
			}
		}

		&--body {
			@include bp-down(smallxl) {
				&:nth-child(even) {
					background-color: rgba($color-border-review, 0.3);
				}
			}
		}
	}

	&__cell {
		padding: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
		border-right: 1px solid $color-grey-disable;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		color: $color-secondary;

		@include bp-down(xlarge) {
			padding: 12px 5px;
		}

		@include bp-down(medium) {
			font-size: 11px;
			line-height: 16px;
		}

		@include bp-down(smallxl) {
			font-size: 12px;
			line-height: 18px;
			border: none;
		}

		p {
			margin-bottom: 5px;
		}

		&:last-child {
			border-right: none;
		}

		&--head {
			font-weight: 600;

			@include bp-down(medium) {
				font-size: 10px;
				line-height: 14px;
			}

			@include bp-down(smallxl) {
				font-size: 12px;
			}
		}

		&--wider {
			flex-basis: 25%;

			@include bp-down(medium) {
				flex-basis: 22%;
			}

			@include bp-down(smallxl) {
				flex-basis: 100%;
			}
		}

		&--mobile {
			display: none;

			@include bp-down(smallxl) {
				display: flex;
				width: 100%;
				flex-basis: 100%;
				background-color: $color-border-review;
			}
		}
	}
}
