/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
	font-size: 2.25rem;
}

h2 {
	font-size: 1.75rem;
}

h3 {
	font-size: 24px;
	line-height: 36px;
}

h4 {
	font-size: 1.25rem;
}

h5 {
	font-size: 1.125rem;
}

h6 {
	font-size: 1rem;
}

.c-heading {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.3;
	color: $color-secondary;

	&--centered {
		text-align: center;
	}
}
