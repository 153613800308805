.b-image-text {
	$root: &;

	padding: 40px 0;

	&--reverse-cols {
		.row {
			display: flex;
			flex-direction: row-reverse;
		}
		#{$root}__content {
			padding-left: 0;
			padding-right: 80px;

			@include bp-down(medium) {
				padding-right: 0;
			}
		}
	}

	&__title {
		margin: 0 0 30px 0;
	}

	&__content {
		padding-left: 80px;

		@include bp-down(medium) {
			padding-left: 0;
			margin: 20px 0;
		}
	}

	a {
		display: inline-block;
		margin: 15px 0 0 0;
	}
}
