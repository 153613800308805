.b-link {
	padding: 40px;

	@include media-breakpoint-up(lg) {
		padding: 73px;
	}

	.col-auto {
		text-align: center;
	}

	a {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		text-align: center;
		text-decoration-line: underline;
		color: $color-secondary;
		transition: $global-transition;

		&:hover,
		&:focus {
			color: $color-fill-accent;
		}
	}
}
