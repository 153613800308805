// ==========================================================================
// #BREAKPOINTS
// ==========================================================================

@function bp-val($name) {
	@if type-of($name) == 'number' {
		@return $name;
	}

	$val: map-get($breakpoints, $name);

	@if $val == null {
		@error 'Breakpoint #{$name} not found';
	}

	@return $val;
}

@function bp-val-next($name) {
	$breakpoint-names: map_keys($breakpoints);
	$n: index($breakpoint-names, $name);

	@if $n >= length($breakpoint-names) {
		@error 'Next breakpoint after #{$name} not found';
	}

	$next-key: nth($breakpoint-names, $n + 1);

	@return map-get($breakpoints, $next-key);
}

@mixin bp($name) {
	$val: bp-val($name);

	@media (min-width: $val) {
		@content;
	}
}

@mixin bp-down($name) {
	$val: bp-val($name);

	@media (max-width: $val - 0.02) {
		@content;
	}
}

@mixin bp-only($name) {
	@if type-of($name) == 'number' {
		@error '#{$name} should be breakpoint name';
	}

	$min: bp-val($name);
	$max: bp-val-next($name);

	@media (min-width: $min) and (max-width: $max - 0.02) {
		@content;
	}
}

@mixin bp-between($name1, $name2) {
	$min: bp-val($name1);
	$max: bp-val($name2);

	@media (min-width: $min) and (max-width: $max - 0.02) {
		@content;
	}
}
