.c-hamburger {
	$self: &;

	z-index: 10;
	cursor: pointer;
	// width: 48px;
	// height: 48px;
	// margin-right: 9px;

	// &--colored {
	// 	path {
	// 		stroke: $color-brand-1;
	// 	}
	// }

	&__open {
		display: block;
	}

	&__close {
		display: none;
	}

	&.active {
		.c-hamburger__open {
			display: none;
		}

		.c-hamburger__close {
			display: block;
		}
	}

	&--child-template {
		#{$self}__open,
		#{$self}__close {
			display: block;
			line-height: 1;
			padding: 0 8px 2px 12px;
			transition: all 0.2s linear;

			svg {
				transition: all 0.2s linear;
				transform: rotate(0deg);
			}
		}

		&.active {
			#{$self}__additional-text {
				font-weight: 700;
			}
			#{$self}__open {
				display: block;

				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
}
