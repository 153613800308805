$shadow: 0 4px 4px rgba(0, 0, 0, 0.24);

.c-tooltip {
	top: -4px;
	display: inline-block;
	cursor: pointer;
	position: relative;

	&::after {
		content: attr(data-content);
		display: none;
		position: absolute;
		bottom: calc(100% + 15px);
		min-width: 150px;
		font-size: 12px;
		line-height: normal;
		color: $color-secondary;
		padding: 12px;
		border-radius: $global-radius-5 * 2;
		left: 0;
		background-color: $color-white;
		box-shadow: $shadow;
	}

	&:hover,
	&:focus {
		&::after {
			display: block;
		}
	}
}
