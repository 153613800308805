.c-review-simple {
	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.2;

		&-name {
			font-weight: 700;
			color: $color-secondary;
		}

		&-date {
			color: $color-primary;
		}
	}

	&__content {
		font-size: 13px;
		font-size: 400;
		line-height: 1.5;
		color: $color-secondary;
	}
}
