.c-box-single-facialitie {
	background: $color-white;
	border: 1px solid $color-gray-border-v2;
	border-radius: 4px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;

	&__header {
		text-align: center;
		padding: 0 10px;
	}

	&__name {
		margin-bottom: 0;
		font-size: 16px;
		line-height: 1.3;
		font-weight: 600;
		color: $color-secondary;
	}

	&__segment {
		padding: 24px;
		border-bottom: 1px solid $color-fill-grey;

		&--header {
			min-height: 112px;
		}

		&:last-child {
			border-bottom: none;
		}
	}

	&__adress {
		font-size: 16px;
		font-weight: 400;
		color: $color-secondary;

		&-street {
			text-transform: capitalize;
		}
	}

	&__rating {
		margin: 24px 0;
		padding: 0 10px;
	}
}
