/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
	color: $color-secondary;
	text-decoration: underline;

	// &:hover {
	// color: $color-hover;
	// }
}
