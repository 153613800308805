// ==========================================================================
// #CLEARFIX
// ==========================================================================

// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include clearfix();
// }

@mixin clearfix() {
	&::after {
		content: '' !important;
		display: block !important;
		clear: both !important;
	}
}
