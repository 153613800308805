/* stylelint-disable selector-max-compound-selectors, declaration-block-no-duplicate-properties */

.c-popup-search {
	$root: &;

	&--onkoindex {
		--popup-title-color: #{$color-secondary};
		--popup-title-font-size: 22px;
	}

	&__content {
		display: flex;
		align-items: flex-start;

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}
	}

	&__title {
		margin-bottom: 26px;
		color: var(--popup-title-color, #000);
		font-size: var(--popup-title-font-size, 32px);
		font-weight: 700;
		line-height: 48px;
	}

	&__text {
		margin: 26px 0;
		font-size: em(20);
		line-height: em(30);
	}

	&__link {
		display: inline-block;
		margin-top: 26px;
	}

	&__left {
		width: 50%;
		padding-right: 15px;

		@include bp-down(medium) {
			width: 100%;
			padding-right: 0;
		}
	}

	&__right {
		width: 50%;

		@include bp-down(medium) {
			width: 100%;
			margin-top: 30px;
		}
	}

	&__reasons {
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
	}

	&__collapse {
		margin-left: 0;
		&.c-collapse--show #{$root}__collapse-title div {
			background: #11c0f2;
			transform: rotate(-180deg);

			path {
				stroke: #fff;
			}
		}
	}

	&__collapse-title {
		margin-bottom: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;

		div {
			margin-left: 16px;
			display: inline-block;
			min-width: 42px;
			width: 42px;
			height: 42px;
			border: 1px solid #11c0f2;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__collapse-content {
		margin-bottom: 15px;
	}

	&__treatment-title {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	&__treatment-content {
		margin-left: 40px;

		@include bp-down(medium) {
			margin-left: 10px;
		}
	}

	&__treatment-sub-title {
		margin-left: 40px;
		font-weight: 700;

		@include bp-down(medium) {
			margin-left: 10px;
		}
	}

	&__refunds {
		margin: 15px 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__refund {
		display: flex;
		align-items: center;

		svg {
			margin-right: 15px;
		}

		&--first {
			margin-right: 30px;
		}

		&--ok {
			color: #249b4d;
		}

		&--fail {
			color: #ed2369;
		}
	}

	&__more {
		width: 100%;
		display: inline-block;
		margin: 0 0 15px 0;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		text-decoration: none;
	}

	&__results {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			@include media-breakpoint-up(lg) {
				columns: 2;
				column-gap: 60px;
			}

			li {
				padding: 8px 0;
				color: $color-secondary;
				font-size: 15px;
				margin-bottom: 4px;
				cursor: pointer;
				transition: $global-transition;

				svg {
					flex-shrink: 0;
					margin-left: 15px;

					path {
						transition: $global-transition;
					}
				}

				&:hover {
					color: $color-fill-accent;

					path {
						stroke: $color-fill-accent;
					}
				}
			}
		}
	}

	&__tabs {
		border: 2px solid $color-fill-accent;
		max-width: max-content;
		margin: 0 auto 28px auto;
		border-radius: $global-radius-5;
		color: $color-fill-accent;
	}

	&__tab {
		padding: 8px 24px;
		font-weight: 600;
		font-size: 16px;
		text-transform: uppercase;
		transition: $global-transition;
		cursor: pointer;

		@include media-breakpoint-down(md) {
			width: 100%;
			text-align: center;
		}

		&:hover,
		&.active {
			color: $color-white;
			background-color: $color-fill-accent;
		}
	}

	&__pagination {
		$margin: 28px;

		margin-top: calc($margin * 2);

		@include media-breakpoint-down(md) {
			margin-top: calc($margin / 2 * 2);
		}
	}
}

/* stylelint-enable selector-max-compound-selectors, declaration-block-no-duplicate-properties */
