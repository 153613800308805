.c-default-slider {
	position: relative;

	&.swiper {
		padding-bottom: 60px;
	}

	&__pagination {
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			margin: 0 12px;
			opacity: 1;
			background-color: $color-light-grey-2;

			&-active {
				background-color: $color-primary;
				opacity: 1;
			}
		}
	}
}
