/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
	display: inline-block; /* [1] */
	vertical-align: middle; /* [2] */
	font: inherit; /* [3] */
	text-align: center; /* [4] */
	margin: 0; /* [4] */
	cursor: pointer; /* [5] */
	padding: 0.5rem 1rem;
	transition: $global-transition;
	border-radius: $global-radius-4;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
	background-color: $color-white;
	transition: $global-transition;
	border: 1px solid $color-fill-accent;
	color: $color-fill-accent;
	text-transform: uppercase;
	border-radius: 4px;
	text-decoration: none;
	font-weight: 500;
	font-size: em(16);
	line-height: normal;
	padding: 7px 13px;
	text-align: center;
	letter-spacing: 0.457143px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [4] */
		color: $color-white;
		background-color: $color-fill-accent;
	}
}

.c-btn--accent {
	background-color: var(--btn-accent-bg, $color-fill-accent);
	transition: $global-transition;
	border: 1px solid var(--btn-accent-bg, $color-fill-accent);
	color: var(--btn-accent-txt, $color-white);
	text-transform: uppercase;
	border-radius: 4px;
	text-decoration: none;
	font-weight: 500;
	font-size: em(16);
	line-height: normal;
	padding: 10px 13px;
	text-align: center;
	letter-spacing: 0.457143px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [4] */
		color: var(--btn-accent-bg, $color-fill-accent);
		background-color: var(--btn-accent-txt, $color-white);
	}
}

.c-btn--secondary {
	background-color: transparent;
	transition: $global-transition;
	border-bottom: 0;
	color: $color-fill-accent;
	border-radius: 0;
	text-decoration-line: underline;
	font-weight: 500;
	font-size: em(16);
	line-height: normal;
	padding: 7px 13px;
	text-align: center;

	&:hover,
	&:active,
	&:focus {
		color: $color-secondary;
	}
}

.c-btn--third {
	background-color: transparent;
	transition: $global-transition;
	border: 1px solid $color-white;
	color: $color-white;
	text-transform: uppercase;
	border-radius: 4px;
	text-decoration: none;
	font-weight: 500;
	font-size: em(16);
	line-height: normal;
	padding: 10px 13px;
	text-align: center;
	letter-spacing: 0.457143px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [4] */
		color: $color-fill-accent;
		background-color: $color-white;
	}
}

.c-btn--icon {
	display: flex;
	align-items: center;

	span {
		padding-left: 10px;
		transition: $global-transition;
	}

	&:hover {
		span {
			transform: translateX(5px);
		}
	}
}

.c-btn--normal {
	color: $color-primary;
	background-color: $color-white;
	border: 1px solid transparent;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	transition: $global-transition;
	box-shadow: none;

	&:hover,
	&:active,
	&:focus {
		border-color: $color-primary;
	}
}

/* Size variants
   ========================================================================== */

.c-btn--small {
	padding: 0.25rem 0.5rem;
}

.c-btn--large {
	padding: 9px 17px;
}

/* fonts bigger
   ========================================================================== */
.c-btn--size {
	&-bigger {
		font-size: 20px;

		@include bp-down(mediumxl) {
			font-size: 18px;
		}
	}
}
