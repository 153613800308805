.b-shortest-queue {
	padding: 40px 0;

	&__heading {
		color: $color-secondary;
		margin: 0 0 12px 0;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin: 0;
			text-align: right;
		}

		&--center {
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
		}
	}

	&__all {
		margin-top: 36px;

		@include media-breakpoint-up(lg) {
			margin-top: 55px;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: center;

		&--twice {
			margin-top: 30px;
		}
	}

	&__input {
		margin-left: 20px;
	}

	&__wrap-boxes {
		width: 100%;
		max-width: 900px;
		margin: 40px auto 0 auto;
	}

	&__box {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
		flex-wrap: nowrap;
		margin-bottom: 18px;
		background-color: $color-white;
		// box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
		border-radius: 10px;
		border: 1px solid #ebebeb;
		gap: 0;

		&-col {
			padding: 19px;

			&--first {
				width: 20%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #f9f9f9;
				border-radius: 10px 0 0 10px;
			}

			&--second {
				width: 80%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				@include media-breakpoint-down(md) {
					flex-wrap: wrap;
				}
			}
		}

		&-days {
			@include media-breakpoint-down(md) {
				text-align: center;
			}

			.days-num {
				display: inline-block;
				line-height: 1;
				font-size: 3.5em;
				font-weight: 600;
				margin: 0 4px;

				@include media-breakpoint-down(md) {
					font-size: 40px;
					display: block;
				}
			}
		}

		&-service-name {
			display: block;
			margin: 0 0 10px;
			font-size: 21px;
			line-height: 1.1;
			font-weight: 600;

			@include media-breakpoint-down(md) {
				font-size: 17px;
			}

			&::first-letter {
				text-transform: uppercase;
			}
		}

		&-city-name {
			font-size: 16px;
			font-weight: 400;
			color: #828282;

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}

		&-btn-warp {
			@include media-breakpoint-down(md) {
				display: block;
				width: 100%;
			}

			.c-btn {
				@include media-breakpoint-down(md) {
					margin-top: 5px;
					padding: 6px 13px;
				}
			}
		}
	}

	&__wrap-all-content {
		position: relative;
	}

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		min-height: 100px;

		@include media-breakpoint-down(md) {
			border-radius: 0;
		}
	}
}
