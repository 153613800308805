.b-doctors-nearby {
	position: relative;
	padding: 140px 0 103px;

	&__title {
		margin-bottom: 18px;
	}

	&__wrap-inputs {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 24px;
		margin-bottom: 48px;
	}

	&__single-col {
		margin-bottom: 20px;
	}

	&__wrapp-btns {
		text-align: right;
		margin: 55px 0 0;
	}
}
