.c-link {
	$self: &;

	&--underline-disable {
		text-decoration: none;
	}

	&--arrow {
		display: inline-flex;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
	}

	&__icon {
		position: relative;
		margin-left: 16px;
		left: 0;
		transition: $global-transition;

		path {
			transition: $global-transition;
		}
	}

	&:hover {
		transition: $global-transition;
		color: $color-fill-accent;
		#{$self}__icon {
			left: -3px;

			path {
				stroke: $color-fill-accent;
			}
		}
	}
}
