/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@mixin fontfaces($arr, $font, $style) {
	@each $n, $w in $arr {
		@font-face {
			font-family: $font;
			font-style: $style;
			font-weight: $w;
			font-display: swap;
			src: url('~assets/fonts/#{$n}.ttf') format('truetype');
		}
	}
}

$poppins: (
	'Poppins-Light': 300,
	'Poppins-Regular': 400,
	'Poppins-Medium': 500,
	'Poppins-SemiBold': 600,
	'Poppins-Bold': 700,
	'Poppins-ExtraBold': 800,
);

$poppins-italic: (
	'Poppins-SemiBoldItalic': 600,
);

// $opensans: (
// 	'OpenSans-Regular': 400,
// 	'OpenSans-Medium': 500,
// 	'OpenSans-SemiBold': 600,
// 	'OpenSans-Bold': 700,
// );

// $opensans-italic: (
// 	'OpenSans-Italic': 400,
// 	'OpenSans-SemiBoldItalic': 600,
// );

@include fontfaces($poppins, $font-name, normal);
@include fontfaces($poppins-italic, $font-name, italic);
// @include fontfaces($opensans, $font-name-text, normal);
// @include fontfaces($opensans-italic, $font-name-text, italic);
