.c-search-ring {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;

	&--hidden {
		display: none;
	}
}

.c-search-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 8px;
	border: 4px solid #ed2369;
	border-radius: 50%;
	animation: c-search-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #ed2369 transparent transparent transparent;
}

.c-search-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.c-search-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.c-search-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes c-search-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
