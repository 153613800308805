.c-popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, 0.4);
	z-index: 99999;

	&--show {
		display: block;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__content {
		padding: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 1200px;
		min-height: 188px;
		background: #fff;
		z-index: 2;
		overflow: auto;
		max-height: calc(100vh - 30px);

		@include bp-down(medium) {
			width: calc(100% - 30px);
			height: calc(100vh - 30px);
			padding: 32px 12px 12px 12px;
			top: 15px;
			bottom: 15px;
			transform: none;
			left: 15px;
			right: 15px;
		}
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		z-index: 9999;
		width: 50px;
		height: 50px;
		background: #11c0f2;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down(md) {
			width: 25px;
			height: 25px;
			top: 8px;
			right: 8px;
		}
	}

	&__title {
		text-align: center;
		margin: 0 0 32px 0;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;

		@include bp-down(medium) {
			margin: 0 0 16px 0;
		}
	}

	&__text {
		text-align: center;

		p {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
