.b-map-places {
	font-size: 0;
	margin: -67px 0 0 0;
	padding: 0;
	height: 500px;

	@include bp-down(mediumxl) {
		height: 300px;
		margin: 0;
	}

	&--disable-margin {
		margin: 0;
	}

	&__map {
		height: 100%;
		width: 100%;
	}
}
