.c-collapse {
	$root: &;

	&--show {
		#{$root}__content {
			display: block;
		}
	}

	&__header {
		cursor: pointer;
	}

	&__content {
		display: none;
	}
}
