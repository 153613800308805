.c-rating-value {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: 14px;

	&--between {
		justify-content: space-between;
	}

	&--end {
		justify-content: flex-end;

		@include bp-down(small) {
			justify-content: flex-start;
		}
	}

	&__additional-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: 14px;
	}

	&__stars {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 3px;
	}

	&__rating {
		padding: 4px 12px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 700;
		color: $color-white;
		background-color: $color-rating-green;
	}

	&__rating-count {
		font-size: 13px;
		font-weight: 400;
		color: $color-secondary;
	}
}
