.b-latest-reviews {
	position: relative;
	padding: 140px 0 80px;

	&__title {
		margin-bottom: 48px;
	}

	&__wrapp-btns {
		text-align: right;
		margin: 55px 0 0;
	}

	&__col-rev {
		&:last-child {
			.c-review-long {
				margin-bottom: 0;
			}
		}
	}
}
