/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
	$root: &;

	z-index: 2;

	@include media-breakpoint-down(lg) {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100vh;
		background-color: $color-white;
		overflow-y: auto;
	}

	> ul {
		list-style: none;
		padding: 0;
		margin: 0;
		gap: 24px;

		@include media-breakpoint-down(lg) {
			justify-content: stretch;
			gap: 0;
			width: 100%;
		}
	}

	&__item {
		position: relative;

		@include media-breakpoint-up(lg) {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 92px;

			&:hover {
				#{$root}__dropdown {
					display: block;
				}
			}
		}

		@include media-breakpoint-down(lg) {
			text-align: center;
		}

		&.menu-item-has-children {
			.arrow-show-menu {
				display: none;
				padding: 3px 5px;
				line-height: 1;
				vertical-align: middle;
				transform: rotate(0);
				transition: all 0.2s linear;

				@include media-breakpoint-down(lg) {
					display: inline-block;
				}
			}

			@include media-breakpoint-down(lg) {
				&.show-sub {
					margin: 5px 0;
					background-color: rgba(0, 0, 0, 0.1);

					#{$root}__link {
						font-weight: 700;
					}

					#{$root}__dropdown {
						display: block;
					}

					.arrow-show-menu {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&__link {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.25;
		letter-spacing: 0.25px;
		color: $color-secondary;
		transition: $global-transition;

		&:hover {
			color: $color-fill-accent;
		}
	}

	&__dropdown {
		display: none;
		list-style: none;
		position: absolute;
		top: 100%;
		left: -10px;
		min-width: 200px;
		padding: 0;
		background-color: $color-primary;
		margin: 0;

		.c-main-nav__sublink {
			transition: all 0.2s linear;
			text-decoration: none;
		}

		@include media-breakpoint-up(lg) {
			li {
				margin: 0;
			}

			.c-main-nav__sublink {
				display: block;
				width: 100%;
				padding: 10px 15px;
				color: $color-white;
				background-color: transparent;
				transition: all 0.2s linear;
				text-decoration: none;

				&:hover {
					// color: $color-fill-accent;
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
		}

		@include bp-down(large) {
			display: none;
			left: 0;
			margin: 5px 0;
			position: relative;
			min-width: auto;
			padding: 0;
			text-align: center;
			background-color: transparent;

			.c-main-nav__sublink {
				background-color: transparent;
				font-size: 15px;
			}
		}
	}
}
