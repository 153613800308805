.b-partners {
	background-color: $color-fill-card;

	&__heading {
		font-weight: 700;
		font-size: em(16);
		line-height: normal;
		color: $color-secondary;
		// padding: 1% 24px 1% 0;
		padding: 0 24px;
		margin: 0;

		@include bp-down(medium) {
			padding: 0;
			margin-top: 0;
			font-size: em(16);
			// padding: 48px 24px 24px 0;
		}
	}

	&__single {
		padding: 0 15px;
	}

	&__images {
		// padding: 3% 0;
		padding: 40px 0 20px;

		img {
			width: 100%;
			height: auto;
		}
	}
}
