$shadow: 0 4px 4px rgba(0, 0, 0, 0.24);

.b-search {
	&__wrapper {
		background-color: $color-white;
		box-shadow: $shadow;
		border-radius: $global-radius-4 * 3;
		padding: 24px;
		position: relative;
		z-index: 1;
		margin: 24px 0 30px 0;
		border: 1px solid $color-fill-grey;

		@include media-breakpoint-up(xl) {
			margin: -50px 0 0 0;
			border: 0;
			border-radius: $global-radius-5 * 2;
		}

		&--onkosnaper {
			margin: 24px 0 30px 0;

			@include media-breakpoint-up(xl) {
				max-width: 752px;
				margin: -50px auto 0 auto;
				border: 0;
				border-radius: $global-radius-5 * 2;
			}
		}
	}

	&__row-item {
		gap: 24px 36px;

		&--disable-gap {
			gap: 0;
		}
	}

	&__item {
		&--custom-select {
			position: relative;
		}

		&--select {
			@include media-breakpoint-up(xl) {
				width: 100%;
				// max-width: 222px;
				max-width: 210px;
			}

			&-bigger {
				@include media-breakpoint-up(xl) {
					width: 100%;
					max-width: 252px;
				}

				@media (max-width: 1399.5px) {
					max-width: 210px;
				}

				@media (max-width: 1199.5px) {
					max-width: 100%;
				}
			}

			@media screen and (min-width: 1100px) {
				font-size: 80% !important;
			}
		}

		&--disabled {
			* {
				color: $color-grey-dark !important;
				border-color: $color-grey-dark !important;
			}

			.c-form__selected::after {
				content: url('~assets/images/icon-select-disabled.svg');
			}
		}
	}

	&__submit {
		input {
			@include media-breakpoint-down(xl) {
				width: 100%;
				font-size: em(20);
			}
		}

		&--onkoindex {
			input {
				min-width: 164px;
			}
		}
	}

	&__tooltip {
		margin-left: 8px;
		cursor: pointer;
		position: relative;

		&::after {
			content: attr(data-content);
			display: none;
			position: absolute;
			bottom: calc(100% + 15px);
			min-width: 150px;
			font-size: 12px;
			line-height: normal;
			color: $color-secondary;
			padding: 12px;
			border-radius: $global-radius-5 * 2;
			left: 0;
			background-color: $color-white;
			box-shadow: $shadow;
		}

		&:hover,
		&:focus {
			&::after {
				display: block;
			}
		}
	}

	&__custom-select {
		display: none;
		max-height: 13rem;
		overflow: auto;

		&--show {
			display: block;
			position: absolute;
			top: calc(100% + 5px);
			left: 0;
			width: 100%;
			background: $color-white;
			border: 1px solid #ced4da;
			padding: 15px 0;
			border-radius: 5px;
			color: #003b50;
			font-style: normal;
			font-weight: 500;
			font-size: 1em;
			line-height: 1.25;
		}
	}

	&__col {
		&--select,
		&-text {
			flex-shrink: 1;
		}

		&--radios {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 1px;
				height: 100%;
				border-right: 2px solid $color-grey-disable;

				@include bp-down(mediumxl) {
					top: auto;
					bottom: -14px;
					left: 0;
					right: 0;
					border: none;
					height: auto;
					width: 100%;
					border-bottom: 2px solid $color-grey-disable;
				}
			}
		}

		&.hidden {
			display: none;
		}
	}

	&__custom-select-option {
		padding: 10px 20px 10px 40px;
		cursor: pointer;
		display: block;
		text-decoration: none;

		&:hover {
			background: #ed2369;
			color: #fff;
		}

		&--bold {
			margin: 0;
			padding: 5px 0 10px 20px;
			font-weight: 700;

			&:hover {
				background: transparent;
				color: inherit;
			}
		}
	}

	&__search-field {
		position: relative;

		.c-search-ring {
			position: absolute;
			top: 42%;
			right: 4px;
			transform: translateY(-50%);
		}
	}

	&__list {
		padding: 53px 0 98px 0;

		@include media-breakpoint-down(lg) {
			padding: 0 0 58px 0;
		}
	}

	&__heading {
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 48px;
		text-align: center;
		color: $color-secondary;
		margin: 0 0 48px 0;
	}

	&__text {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: $color-secondary;
		margin: 0;
		padding-bottom: 77px;
	}

	&__information {
		padding-bottom: 139px;

		@include media-breakpoint-down(md) {
			padding-bottom: 89px;
		}
	}

	&__countries {
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		position: relative;

		&-current {
			cursor: pointer;
			transition: $global-transition;

			svg {
				path {
					transition: $global-transition;
				}
			}

			&:hover {
				color: $color-fill-accent;

				svg {
					path {
						stroke: $color-fill-accent;
					}
				}
			}
		}

		&-active {
			border-bottom: 2px solid $color-fill-accent;
		}

		&-list {
			// display: none;
		}
	}
}
