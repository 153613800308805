.b-banner-inf {
	position: relative;
	padding: 48px 0;
	background-color: $color-fill-accent;

	&__wrapper-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&--centered {
			justify-content: center;
		}

		@include bp-down(mediumxl) {
			flex-wrap: wrap;
			justify-content: center;
			gap: 20px;
			text-align: center;
		}
	}

	&__title {
		margin: 0;
		padding-right: 60px;
		color: $color-white;

		@include bp-down(mediumxl) {
			padding-right: 0;
		}

		&--centered {
			text-align: center;
			padding: 0;
		}
	}

	&__btn {
		white-space: nowrap;
	}
}
