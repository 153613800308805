.c-review-long {
	position: relative;
	margin-bottom: 48px;
	padding-left: 60px;

	@include bp-down(medium) {
		padding-left: 0;
	}

	&__wrapper {
		padding: 16px;
		border: 2px solid $color-border-review;
		border-radius: 6px;
		background-color: $color-bg-review;
	}

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;

		@include bp-down(small) {
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		&-left,
		&-right {
			@include bp-down(small) {
				width: 100%;
			}
		}

		&-right {
			@include bp-down(small) {
				margin-top: 10px;
			}
		}

		&-name {
			font-size: 16px;
			line-height: 1.3;
			font-weight: 700;
			letter-spacing: 0.03em;
			color: $color-secondary;
		}

		&-date {
			padding-left: 9px;
			font-size: 12px;
			line-height: 1.3;
			font-weight: 400;
			color: $color-gray-v3;
		}
	}

	&__text {
		margin-bottom: 12px;
		font-size: 14px;
		line-height: 1.3;
		font-weight: 400;
		color: $color-gray-v3;

		&--footer {
			margin: 0;
			color: $color-secondary;

			strong {
				text-transform: capitalize;
			}
		}
	}

	&__footer {
		padding-top: 12px;
		border-top: 1px solid $color-border-review;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 44px;
			height: 44px;
			border: 50%;

			@include bp-down(medium) {
				display: none;
			}
		}
	}
}
