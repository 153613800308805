/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
	@include clearfix();

	margin-right: auto;
	margin-left: auto;
	max-width: 1200px;
}
