.c-select-list {
	&__current {
		svg {
			transition: $global-transition;
		}

		&.active {
			color: var(--select-list-current, $color-fill-accent);

			svg {
				transform: rotate(180deg);

				path {
					stroke: var(--select-list-current, $color-fill-accent);
				}
			}
		}
	}

	&__wrapper {
		max-height: 246px;
		will-change: auto;
		position: absolute;
		z-index: 5;
		top: calc(100% + 0.625rem);
		left: 50%;
		transform: translateX(-50%);
		transform: translateX(-50%);
		width: auto;
		opacity: 0;
		visibility: hidden;
		border-radius: 0.4375rem;
		transition: all 0.12s ease-in-out;

		&.active {
			opacity: 1;
			visibility: visible;
		}

		&::before {
			content: '';
			position: absolute;
			top: -0.3125rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			transform: translateX(-50%) rotate(45deg);
			width: 0.75rem;
			height: 0.75rem;
			background-color: #11c0f2;
		}
	}

	&__items {
		overflow: auto;
		text-align: left;
		border-radius: 0.4375rem;
		background-color: #11c0f2;
		font-size: 0.875rem;
		font-weight: 700;
		color: #fff;
		width: max-content;
		max-height: 15rem;
		z-index: 5;
		list-style: none;
		padding: 8px 0;

		&::-webkit-scrollbar {
			width: 0.4375rem;
			margin-right: 0.625rem;
		}

		&::-webkit-scrollbar-thumb {
			background: #fff;
			border-radius: 0.25rem;
		}
	}

	&__item {
		position: relative;
		cursor: pointer;
		padding: 0.375rem 2.1875rem 0.375rem 1.5625rem;
		line-height: 1.25rem;
		max-width: 500px;
		word-break: break-word;
		white-space: normal;

		&:hover {
			background-color: var(--select-list-item-hover, $color-fill-accent);
		}

		&.active {
			&::after {
				position: absolute;
				right: 1.09375rem;
				top: 50%;
				transform: translate(50%, -50%);
				content: '';
				display: block;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 100%;
				background: #fff;
			}
		}
	}
}
