/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 8px;
	padding-bottom: 8px;
	z-index: 999;
	background: #fff;
	height: 92px;
	display: flex;
	align-items: center;

	&__logo {
		margin-right: 61px;

		@include media-breakpoint-up(lg) {
			margin-right: 48px;
		}

		@include media-breakpoint-down(lg) {
			// z-index: 99;
		}
	}

	&__number {
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
		letter-spacing: 0.1px;
		margin-left: 12px;
		color: $color-dark-orange;
	}

	img {
		height: auto;
		max-height: 70px;
		width: auto;
	}
}
