.c-accordion {
	$self: &;

	margin: 24px 0;

	&--with-table {
		#{$self}__item {
			padding: 0;
		}
		#{$self}__btn {
			padding: 15px 20px 15px 16px;
		}
		#{$self}__text {
			padding: 0 0 0 0;
			border-top: 1px solid $color-grey-disable;
		}
		#{$self}__icon-arrow {
			right: 16px;
		}
	}

	&__item {
		overflow: hidden;
		margin: 0;
		padding: 0 16px 0;
		transition: $global-transition;
		border: 1px solid $color-grey-disable;
		border-radius: 4px;
	}

	&__btn {
		position: relative;
		display: block;
		width: 100%;
		padding: 15px 20px 15px 0;
		background-color: transparent;
		border: none;
		text-align: left;
		outline: none;
		cursor: pointer;

		&:focus,
		&:active,
		&:hover {
			outline: none;
			border: none;
			box-shadow: none;
		}

		&.active {
			#{$self}__icon-arrow {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	&__icon-arrow {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		transform-origin: center;
	}

	&__btn-text {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: $color-secondary;
	}

	&__description {
		transition: height 0.3s linear;
		overflow: hidden;

		a {
			text-decoration: underline;
		}

		&:not(.is-active) {
			display: none;
		}
	}

	&__text {
		padding: 15px 0 0 0;
		font-size: 16px;
		line-height: 24px;

		ul,
		ol {
			padding-left: 20px;
		}
	}
}
