.l-menu-child-template {
	$self: &;

	position: relative;
	scroll-behavior: smooth;

	&--reverse-content {
		#{$self}__row {
			flex-direction: row-reverse;

			@include bp-down(large) {
				flex-direction: row;
			}
		}

		#{$self}__col {
			&--menu {
				padding-left: 30px;
				text-align: right;

				@include bp-down(large) {
					padding-left: 15px;
					text-align: left;
				}
			}

			&--child-content {
				padding-right: 30px;

				@include bp-down(large) {
					padding-right: 15px;
				}
			}
		}
	}

	&__content-children {
		position: relative;
		padding: 100px 0;

		@include bp-down(large) {
			padding: 50px 0;
		}
	}

	&__col {
		&--menu {
			padding-right: 30px;

			@include bp-down(large) {
				margin-bottom: 30px;
				padding-right: 15px;
			}
		}

		&--child-content {
			padding-left: 30px;

			@include bp-down(large) {
				padding-left: 15px;
			}
		}
	}

	&__menu-wrap {
		position: sticky;
		top: 120px;
		left: 0;
	}

	&__title {
		display: block;
		margin-bottom: 40px;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $color-secondary;

		@include bp-down(large) {
			display: none;

			&--mobile {
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 2px;
				line-height: 1;
			}
		}
	}

	&__child-wrapper {
		position: relative;
		margin-bottom: 100px;

		.has-text-align-center {
			text-align: center !important;
		}

		.wp-block-separator,
		.wp-block-image {
			margin: 48px 0;
		}
	}

	&__menu {
		&-item {
			display: block;
			padding: 12px 0;
			border-bottom: 1px solid $color-grey-disable;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			text-decoration: none;
			color: $color-secondary;

			@include bp(large) {
				&.is-active,
				&:hover {
					font-weight: 600;
				}
			}

			&:last-child {
				border-bottom: none;
			}
		}

		@include bp-down(large) {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	&__mobile-burger {
		display: none;
		margin-left: -11px;

		@include bp-down(large) {
			display: block;
		}

		&-inner {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}
	}
}
