.b-two-columns-tib {
	--margin-bottom: 30px;
	$root: &;

	background-color: var(--two-columns-tib-background, #fff);
	padding-top: 66px;
	padding-bottom: 66px;

	@include media-breakpoint-up(lg) {
		--margin-bottom: 48px;

		padding-top: 96px;
		padding-bottom: 96px;
	}

	&__row {
		@include media-breakpoint-down(lg) {
			--bs-gutter-y: calc(var(--bs-gutter-x) * 2);
		}

		&--more {
			--bs-gutter-y: calc(var(--margin-bottom) * 2);
		}
	}

	&__col {
		&--border-right {
			@include media-breakpoint-up(lg) {
				border-right: 1px solid var(--two-columns-tib-border, transparent);
			}
		}

		@include media-breakpoint-down(lg) {
			#{$root}__box {
				border-bottom: 1px solid var(--two-columns-tib-border, transparent);
				padding-bottom: calc(var(--bs-gutter-x) * 2);
			}

			&:last-child {
				#{$root}__box {
					border-bottom: 0;
				}
			}
		}
	}

	&__box {
		padding: 0 calc(var(--margin-bottom) / 2);

		@include media-breakpoint-up(lg) {
			padding: 0 var(--margin-bottom);
			height: 100%;
		}
	}

	&__icon {
		padding: 10px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
		border-radius: 4px;
		background-color: var(--two-columns-tib-main);
		margin-bottom: var(--margin-bottom);
		transition: $global-transition;

		&:hover {
			box-shadow: none;
		}

		img {
			width: auto;
		}

		@include media-breakpoint-up(lg) {
			padding: 12px;
		}
	}

	&__title {
		color: var(--two-columns-tib-main);
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 48px;
		margin: 0 0 var(--margin-bottom) 0;
	}

	&__text {
		color: var(--two-columns-tib-main);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: var(--margin-bottom);
	}

	&__link {
		@include media-breakpoint-up(lg) {
			margin-top: auto;
		}
	}
}
