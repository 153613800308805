.b-text {
	padding: 80px 0;
	text-align: center;

	&--light {
		color: #fff;
	}

	a {
		margin: 15px 15px 0 15px;
	}
}
