.c-pagination {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	border: 1px solid $color-light-grey-2;
	width: max-content;
	border-radius: $global-radius-4;

	&__item {
		padding: 5px 12.5px;
		margin: 0;
		border-right: 1px solid $color-light-grey-2;
		color: $color-primary;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		cursor: pointer;
		transition: $global-transition;
		min-width: 36px;
		text-align: center;

		&:last-child {
			border-right: 0;
		}

		&:hover {
			background-color: rgba($color-primary, 0.2);
		}

		&.active {
			background-color: $color-primary;
			color: white;
		}
	}
}
